<template>
  <div class="page">
    <div class="lzapp">
      <div class="item-icon">
        <div class="icon-part">
          <img src="../../assets/images/lzlogo.png" alt="" />
        </div>
        <div class="icon-auth">
          <img src="../../assets/images/au-left.png" alt="" />
          <p>授权</p>
        </div>
        <div class="icon-part">
          <img src="../../assets/images/logo.png" alt="" />
        </div>
      </div>
      <div class="item-con">
        <div class="con-tell">
          <input
            v-model.trim="phoneNumber"
            maxlength="13"
            class="iphone"
            placeholder="请输入懒猪快送登陆手机号码"
            type="text"
          />
          <div class="code">
            <input v-model.trim="verifyCode" type="text" placeholder="请输入验证码" />
            <div
              :class="['send-code', { 'code-active': btnShow }]"
              @click="!btnShow ? getCode() : ''"
            >
              <van-count-down :time="time" @finish="codeFinish">
                <template #default="timeData">
                  <span class="block" v-if="btnShow"
                    >{{ timeData.seconds }}s后重发</span
                  >
                  <span class="block" v-else>获取验证码</span>
                </template>
              </van-count-down>
            </div>
          </div>
        </div>
        <van-button type="primary" @click="clickBindAuth('app')" :loading="loading" loading-text="授权中...">同意授权</van-button>
        <div class="item-des">
          <div class="allow">允许猪耳朵获取以下信息</div>
          <van-checkbox v-model="checked" shape="square" disabled
            >操作您的订单及账户信息</van-checkbox
          >
          <van-checkbox v-model="checked" shape="square" disabled
            >一键发单</van-checkbox
          >
          <van-checkbox v-model="checked" shape="square" disabled
            >共享账户信息</van-checkbox
          >
          <div class="tips">授权成功后，表示【猪耳朵】将获得以上权限</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMobileSystem, getIphoneNumberFormat } from "@/utils";
import { Toast } from 'vant'
import http from "@/http";

export default {
  data() {
    return {
      time: "0",
      btnShow: false,
      show:false,
      checked: true,
      phoneNumber: "",
      verifyCode:'',
      loading:false
    };
  },
  created() {

  },
  mounted() {
    document.title = "懒猪快送授权";
  },
  watch:{
    phoneNumber(newValue,oldValue){
      let value = newValue.replace(/\D/g, '').substr(0, 11) // 不允许输入非数字字符，超过11位数字截取前11位
      let len = value.length
      if (len > 3 && len < 8) {
        value = value.replace(/^(\d{3})/g, '$1 ')
      } else if (len >= 8) {
        value = value.replace(/^(\d{3})(\d{4})/g, '$1 $2 ')
      }
      this.phoneNumber = value
    }
  },
  methods: {
    //获取验证码
    getCode() {
      this.phoneNumber = this.phoneNumber.replace(/\s*/g,"")
      if (!getIphoneNumberFormat(this.phoneNumber)) {
        return Toast('请输入正确手机号');
      }
      if(this.show){
        return
      }
      this.show = true
      http.post('/?cmd=com.xqxc.api.pigear.biz.lazipig.LazipigLoginService.getAuthCode',{
        tel:this.phoneNumber
      }).then(res => {
        if(res.result){
          this.btnShow = true;
          this.time = "60000";
          this.show = false
        }
      }).catch(err => {
        this.show = false
      })
      
    },

    //同意授权
    clickBindAuth(){
      this.phoneNumber = this.phoneNumber.replace(/\s*/g,"")
      if(!this.phoneNumber){
        return Toast('请输入手机号')
      }
      if(!this.verifyCode){
        return Toast('请输入验证码')
      }
      this.loading = true
      http.post('/?cmd=com.xqxc.api.pigear.biz.lazipig.LazipigLoginService.bindAuth',{
        tel:this.phoneNumber,
        code:this.verifyCode,
        shopId:this.$route.query.shop_id,
        userId:this.$route.query.user_id
      }).then(res => {
        this.loading = false
        this.$router.push({path:'/blend-view/lazipigCallback',query:{url:res.result.authUrl,status:res.result.status}})
      }).catch(err => {
        this.loading = false
      })
    },

    codeFinish() {
      this.btnShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  overflow: auto;
  .item-icon {
    width: 4.1rem;
    margin: 0.88rem auto 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    .icon-part {
      text-align: center;
      img {
        width: 1.28rem;
        height: 1.28rem;
        // margin-bottom: 0.2rem;
      }
    }
    .icon-auth {
      margin: 0 0.58rem;
      font-size: 0.24rem;
      color: #666666;
      img {
        width: 0.53rem;
        height: 0.35rem;
        margin: 0.4rem 0 0.2rem;
      }
    }
  }
  .item-con {
    width: 6.9rem;
    margin: auto;
    .con-tell {
      padding: 0 0.44rem;
    }
    input {
      display: block;
      width: 100%;
      height: 0.9rem;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid #dbdbdb;
      border-radius: 0.06rem;
    }
    input::placeholder {
      color: #999999;
      font-size: 0.28rem;
    }
    .code {
      display: flex;
      align-items: center;
      margin: 0.2rem 0 0.48rem;
      border-bottom: 1px solid #dbdbdb;
      input {
        width: 4.5rem;
        border: none;
      }
      .send-code {
        margin-left: auto;
        .block {
          color: #ff2839;
        }
        &.code-active {
          .block {
            color: #ccc;
          }
        }
      }
    }
  }
  .van-button {
    width: 100%;
    background: #ff2839;
    border: #ff2839;
  }
  .item-des {
    font-size: 0.28rem;
    color: #333333;
    padding-left: 0.1rem;
    /deep/ .van-checkbox {
      margin: 0.36rem 0;
      overflow: initial;
      .van-checkbox__label {
        font-size: 0.24rem;
      }
      .van-checkbox__icon,
      .van-badge__wrapper {
        width: 0.35rem;
        height: 0.35rem;
        line-height: 0.35rem;
      }
    }
    .allow {
      margin: 1.1rem 0 0.5rem;
    }
    .tips {
      margin: 1rem 0 0.4rem;
    }
  }
}
</style>
    